.breadcrumbs {
    margin-bottom: 45px;

    &__item {
        display: inline;
        color: #cdcdcd;
        font-size: 12px;
        font-weight: normal;
		font-family: Tahoma, Arial, Verdana, sans-serif;
		line-height: 1.8;

        &:not(:first-child) {
            &::before {
				content: "/";
                padding: 0 5px;
                text-decoration: none;
            }
        }

        a {
			color: #cdcdcd;
			display: inline;
        }
    }
}
