.sitemap {
    a {
        color: #efb75c;
        outline: none;
		text-decoration: none;
		line-height: 1.2;
		padding: 3px 0;
		display: block;
    }

    &__list {
        margin-bottom: 10px;
        list-style: none;
        padding-left: 20px;
	}

    &__item {
        .sitemap__list {
            padding-left: 20px;
        }
    }
}
