/* ==========================================================================
   #Focus
   ========================================================================== */

/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */


:focus {
  outline: none;
}
