.info {
    @include media-breakpoint-up(md) {
        column-count: 2;
        column-gap: 0;
    }

    &__item {
        margin-bottom: 10px;

        a {
            color: #efb75c;
            text-decoration: none;
            padding-right: 15px;
            font-size: 15px;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

