.slider-popular {
    padding: 0 25px;

    .slick-arrow {
		width: 19px;
		height: 28px;
		top: 70px;
    }

    .slick-prev,
    .slick-next {

    }
    .slick-prev {

    }
    .slick-next {
		transform: rotate(180deg);
		top: calc(70px - 15px);

    }
}
