.categories {
    @include media-breakpoint-up(md) {
        column-count: 2;
        column-gap: 0;
    }

    &__item {
        text-align: center;
        margin-bottom: 20px;

        a {
            @extend .headings-h1;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
