.slide {
    display: none;

    &:first-child {
         display: block;
    }

    .slick-initialized & {
         display: block;
    }

    &__link {
        display: block;
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
        }
    }
}
