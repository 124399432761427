.action-btn {
    margin-bottom: 20px;

    a {
        display: block;
        width: 260px;
        background: url(../img/action/action-btn.png) no-repeat;
        background-size: cover;
        text-indent: -9999px;
        font-size: 0;
        cursor: pointer;
        margin: 0 auto;
        max-width: 260px;
        height: 80px;
        background-position: 0 -80px;

        @include media-breakpoint-down(xs) {
            max-width: 195px;
            height: 60px;
            background-position: 0 -60px;
        }

        &:hover {
            background-position: 0 0;
        }
    }
}
