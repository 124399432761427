.service-item {

	&__description {
		p {
			color: #cdcdcd;
			font-size: 15px;
			text-align: justify;
			line-height: 1.2;
			margin-bottom: 13px;			
		}
		a {
			color: #EFB75C;
			text-decoration: none;			
		}
	}

}