.slider-action {
    @extend .slider-popular;

	padding: 0;
	position: relative;

    &__link {
        position: absolute;
        top: 3px;
		right: 30px;
		z-index: 2;


        img {
            width: auto;
        }
    }
}
