.catalog {
	margin-bottom: 35px;

    &__link {
        color: #d9b579;
    }

    &__img {
        display: flex;
        justify-content: center;
		align-items: center;
		margin: 0 auto;
		margin-bottom: 10px;
        border: 5px solid #d9b579;
		width: 100%;
		max-width: 300px;
        // height: 214px;
        overflow: hidden;

        img {
            max-width: none;
            width: 100%;
            height: 100%;
        }
    }

    &__heading {
        font-size: 22px;
        font-family: Georgia, Tahoma, Arial, Verdana, sans-serif;
        color: #d9b579;
        font-style: italic;
        font-weight: normal;
		line-height: 1;
		margin-bottom: 13px;
    }

    &__content {
        color: #cdcdcd;
    }

    &__preview {
		font-size: 15px;
		text-align: justify;
		line-height: (18/15);
    }

    &__criterion {
        @extend .slide-popular__criterion;
    }
}
