.headings-h1,
.headings-h2,
.headings-h3 {
    font-weight: normal;
}

.headings-h1 {
    font-size: 30px;
    font-family: Georgia, Tahoma, Arial, Verdana, sans-serif;
    color: #d9b579;
    font-style: italic;
    font-weight: normal;
    line-height: 1;

    &--dark {
        color: #af8135;
    }

    &--bold {
        font-weight: bold;
        margin-bottom: 20px;
    }

    &--big {
        font-size: 40px;
    }

    &--small {
        font-size: 24px;
    }
}

.headings-h2 {
    font-size: 24px;
    font-family: Georgia, Tahoma, Arial, Verdana, sans-serif;
    color: #d9b579;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 20px;

    &--bold {
        font-weight: bold;
        margin-bottom: 20px;
    }    
}

.headings-h3 {
}

.headings-h4 {
    font-size: 24px;
    font-family: Georgia, Tahoma, Arial, Verdana, sans-serif;
    color: #d9b579;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 32px;
}
