/* ==========================================================================
   #CLEARFIX
   ========================================================================== */

// $grid-columns:                12;
// $grid-gutter-width:           100px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1100px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1030px
);