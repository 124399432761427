.article {
	font-size: 15px;
	margin-bottom: 30px;

    a {
        color: #efb75c;
        text-decoration: none;
		padding-right: 15px;

        &:hover {
            text-decoration: underline;
        }
	}
}
