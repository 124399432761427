.about {
    p {
        color: #1e0c24;
        font-size: 14px;
        font-family: Tahoma, Verdana, Arial, sans-serif;
        font-weight: normal;
        margin-bottom: 10px;
        text-align: justify;
        line-height: (22/14);
	}
	a {
		color: #1e0c24;
	}
}
