.footer-menu {

    @include media-breakpoint-up(xs) {
        margin-bottom: 15px;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }

    &__list {
        display: flex;
		list-style: none;
        flex-wrap: wrap;
        justify-content: space-between;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: block;
        }
    }

    &__item {
        position: relative;
        width: 100%;

        @include media-breakpoint-up(sm) {
            // flex: 0 0 110px;
            width: initial;
        }
        @include media-breakpoint-up(lg) {
            flex: 0 0 auto;
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: inline-block;
            min-width: 110px;
        }

        > a {
            color: white;
            text-decoration: none;
            font-size: 16px;
            font-weight: normal;
            font-family: Georgia, Arial, Tahoma, Verdana, sans-serif;
            padding: 0 20px;
            display: block;

            @include media-breakpoint-up(xs) {
                padding: 0;
            }
            @include media-breakpoint-up(lg) {
                padding: 0 20px;
            }
        }

        &--active {
            > a {
                color: #efb75c;
            }
        }
    }
}
