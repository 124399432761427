.content-img {
    width: calc(100% + 60px);
    min-height: 110px;
    float: left;
    margin: 0 -30px;
    background: url(../img/about/content-main-top.png) left top no-repeat;

    &__fon {
		margin-top: 110px;
		padding-bottom: 30px;
        background: url(../img/about/content-main.png) left top repeat-y;
    }
}
