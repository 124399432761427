.fasad {
	&__img {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		img {
			flex: 1 0 100px;
			max-width: 100px;
			margin-bottom: 20px;

			@include media-breakpoint-up (xs) {
				margin-right: 15px;
			}
			@include media-breakpoint-up (sm) {
				margin-right: 20px;
			}
		}
	}
}