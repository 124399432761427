.main-content {
	// @include media-breakpoint-up (md) {
	// 	flex: 0 0 70%;
	// 	max-width: 70%;
	// 	padding-left: 0;
	// }

	@media(max-width: 500px) {
		padding: 0 !important;
		max-width: 100%;
		flex: 0 0 100%;
	}
}