.contacts {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    line-height: (18/15);

    a {
        color: #efb75c;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &__map {
        @include media-breakpoint-up(xs) {
            flex: 0 0 100%;
            max-width: 100%;
        }
        @include media-breakpoint-up(sm) {
            flex: 0 0 320px;
            max-width: 320px;
            margin-right: 30px;
        }
    }

    &__heading {
        line-height: 1;
    }

    &__subheading {
        font-weight: bold;
    }

    &__address,
    &__phone,
    &__schedule {
        margin-bottom: 10px;
    }

    &__phone {
        a {
            padding-right: 10px;
        }
    }
}
