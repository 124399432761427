.header {
    padding-top: 20px;
    display: flex;

    @include media-breakpoint-up(xs) {
        flex-wrap: wrap;
    }
    @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
    }

    &--wrap {
        @include media-breakpoint-up(xs) {
            background: #311c36;
        }
        @include media-breakpoint-up(md) {
            background: url("../img/header/header.png") no-repeat;
            background-position-y: -26px;
        }
        @include media-breakpoint-up(xl) {
            background-position-y: 0;
        }
    }

    &__logo {
        @include media-breakpoint-up(xs) {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0 10px;
        }
        @include media-breakpoint-up(md) {
            flex: 0 0 auto;
            max-width: 266px;
            margin-left: 27px;
            margin-top: 17px;
            padding: 0;
        }
        @include media-breakpoint-up(xl) {
            max-width: 410px;
            margin-top: 10px;
        }
        @include media-breakpoint-up(xl) {
            max-width: initial;
            margin-top: 0;
        }
    }

    &__contacts {
        @include media-breakpoint-up(xs) {
            flex: 0 0 auto;
            max-width: 100%;
            padding-left: 10px;
        }
        @include media-breakpoint-up(md) {
            flex: 0 0 auto;
            max-width: initial;
            margin-left: 30px;
            padding-left: 0;
        }
    }

    &__city {
        color: #fad98e;
        font-size: 16px;
    }

    &__phone {
        color: #fad98e;
        font-family: Georgia, Tahoma, Verdana, sans-serif;
        font-weight: bold;
        line-height: 1;

        @include media-breakpoint-up(xs) {
            font-size: 4.2vw;
        }
        @include media-breakpoint-up(sm) {
            font-size: 26px;
        }
        @include media-breakpoint-up(md) {
            font-size: 21px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 30px;
        }
    }

    &__order {
        background: url("../img/header/header-order.png") left top no-repeat;
        width: 155px;
        height: 78px;
        margin-left: auto;
        margin-right: -12px;
        flex: 0 0 auto;
        max-width: 155px;

        @include media-breakpoint-up(md) {
            margin-top: 15px;
        }

        > a {
            width: 155px;
            height: 53px;
            display: block;
            margin: 0px 0px 0px 0px;
            text-indent: -9999px;
        }
    }

    &__shortcut {
        display: flex;
        justify-content: center;
    }

    &__icon {
        display: flex;
        align-items: center;

        a {
            padding: 3px 2px;
        }

        &::after {
            content: "•";
            color: #623066;
            font-size: 19px;
            line-height: 1;
        }

        &:last-child {
            &::after {
                content: none;
            }
        }
    }
}
