.menu {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        padding: 8px 0;
    }
    @include media-breakpoint-up(xs) {
        display: none;
        background: #311c36;
    }
    @include media-breakpoint-up(xl) {
        display: block;
        background: none;
    }

    &__list {
        display: flex;
        list-style: none;

        @include media-breakpoint-up(xs) {
            flex-direction: column;
        }
        @include media-breakpoint-up(xl) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__item {
        @include media-breakpoint-up(xs) {
            position: relative;
        }

        @include media-breakpoint-up(xl) {
            background: url(../img/header/menu/border-right.png) right center
                no-repeat;
            position: static;

            &:last-child {

                &:not(.menu__item--sub){
                    background: none;
                }
            }
        }

        > a {
            color: white;
            text-decoration: none;
            font-size: 16px;
            font-weight: normal;
            font-family: Georgia, Arial, Tahoma, Verdana, sans-serif;
            padding: 20px 29px;
            display: block;

            @include media-breakpoint-up(xs) {
                padding: 10px 29px;
            }
            @include media-breakpoint-up(xl) {
                padding: 20px 29px;
            }
        }

        &--active {
            > a {
                color: #efb75c;
            }
        }

        &--instagram {
            > a >span {
                
                @include media-breakpoint-up(xl) {
                    display: none;
                }

            }
        }

        @include media-breakpoint-up(xl) {
            &:hover {
                .menu__sub {
                    display: block;
                }
            }
        }

        &--sub {
            background: #e6cfa9;

            > a {
                padding: 5px 28px;
                color: #b68b44;
                text-decoration: none;
                font-size: 14px;
                font-weight: normal;
                font-family: Georgia, Arial, Tahoma, Verdana, sans-serif;
            }
        }
    }

    &__sub {
        display: none;
        list-style: none;
        z-index: 100;
        margin: 0;
        padding-top: 8px;
        background: url(../img/header/menu/sub-menu.png) center top no-repeat;

        @include media-breakpoint-up(xl) {
            position: absolute;
        }
    }

    &__down {
        @include media-breakpoint-up(xs) {
            height: 30px;
            width: 30px;
            position: absolute;
            top: 5px;
            right: 29px;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.4s ease;
            cursor: pointer;

            img {
                height: 11px;
            }

            &--active {
                transform: rotate(180deg);
            }
        }
        @include media-breakpoint-up(xl) {
            display: none;
        }
    }
}
