.product-info {
    &__price-m {
        font-weight: normal;
        display: block;
        font-family: Arial;
        font-size: 14px;
        line-height: 1.7em;
        margin-bottom: 16px;

        span {
            font-weight: normal;
            font-family: Georgia, Verdana, sans-serif;
            font-size: 20px;
            color: #d9b579;
            font-style: italic;
        }
    }

    &__old_price {
        font-size: 20px;
        line-height: 1;
        font-family: Georgia,Verdana,sans-serif;
        font-weight: 400;
        color:#888;
        font-style: italic;
        margin-bottom: 10px;
        display: -ms-flexbox;
        display: flex;
        text-decoration: line-through;
        text-decoration-color: #888;
        margin-left: 50px;
       
    }

    &__characteristics {
        font-size: 12px;
        margin-bottom: 10px;

        a,
        span {
            color: #efb75c;
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__item {
    }

    &__price {
        font-size: 24px;
        line-height: 1;
        font-family: Georgia, Verdana, sans-serif;
        font-weight: normal;
        color: #d9b579;
        font-style: italic;
        margin-bottom: 20px;
        display: flex;

        &::before {
            content: "";
            background-image: url(../img/product/tsena.png);
            background-repeat: no-repeat;
            width: 38px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }

        span {
            text-indent: -9999px;
            font-size: 0;
        }
    }

    &__calculation {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 25px;
        flex-wrap: wrap;

        span,
        a {
            @include media-breakpoint-up(xs) {
                flex: 0 0 100%;
                max-width: 100%;
            }
            @include media-breakpoint-up(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }

        span {
            padding-right: 15px;

            @include media-breakpoint-up(xs) {
                margin-bottom: 10px;
            }
            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
            }
        }

        a {
            display: flex;
            align-items: center;

            @include media-breakpoint-up(sm) {
                justify-content: flex-end;
            }
        }
    }

    &__description {
        font-size: 14px;
        text-align: justify;
        margin-bottom: 30px;
    }
}
