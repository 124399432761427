.form {
    @include media-breakpoint-up(sm) {
        padding: 0 30px;
    }

    &__group {
        display: flex;
        margin-bottom: 15px;

        @include media-breakpoint-up(xs) {
            flex-direction: column;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                display: block;
            }
        }
        @include media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: flex-end;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                display: flex;
            }
        }

        label {
            padding-right: 15px;
            color: #fad98e;
            font-size: 16px;
            font-weight: normal;

            @include media-breakpoint-up(md) {
                text-align: right;
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    //max-width: 169px;
                }
            }
            @include media-breakpoint-up(lg) {
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    //max-width: none;
                }
            }
        }
    }

    &__field {
        width: 100%;
        flex: 0 0 100%;
        border-radius: 4px;
        padding: 6px;
        border: 1px solid #d3d3d3;

        @include media-breakpoint-up(md) {
            max-width: 400px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 560px;
        }
    }

    &__btn {
        cursor: pointer;
        background-image: linear-gradient(#5e0070 0%, #8400c2 100%);
        text-shadow: 1px 1px 1px #3c003f;
        color: #fff;
        box-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(144, 0, 172, 0.5);
        padding: 6px;
    }

    &__btn-add {
        color: #efb75c;
        font-size: 18px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &--field-style {
        margin-top: 10px;
        padding: 0;
        border: none;
    }

    &__heading {
        color: #fff;
        font-size: 18px;
        margin: 30px 0 25px;
        line-height: 1.2em;
    }

    &__file {
        color: rgb(250, 217, 142);
        flex: 1;
        max-width: 50%;


    }

    &__field-file {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
