.flag {
    > a {
        position: absolute;
        bottom: 10px;
        z-index: 2;

        @include media-breakpoint-up(xs) {
            left: -21px;
        }
        @include media-breakpoint-up(sm) {
            left: -31px;
        }

        &:last-child {
            left: auto;
            text-align: right;

            @include media-breakpoint-up(xs) {
                right: -21px;
            }
            @include media-breakpoint-up(sm) {
                right: -27px;
            }
        }

        img {
            @include media-breakpoint-up(xs) {
                width: 70%;
            }
            @include media-breakpoint-up(sm) {
                width: auto;
            }
        }
    }
}
