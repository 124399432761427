.color-options {
	&__img {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		img {
			flex: 0 0 160px;
			max-width: 220px;
			margin-bottom: 20px;

			@include media-breakpoint-up (xs) {
				margin-right: 15px;
			}
			@include media-breakpoint-up (sm) {
				margin-right: 20px;
			}
		}
	}
}