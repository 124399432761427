.product-gellary {
    &__img {
        margin-bottom: 10px;
    }

    &__thumbnails {
		display: flex;
		margin-bottom: 15px;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            overflow: hidden;
			height: 60px;
			margin-right: 10px;
        }

        img {
            max-width: initial;
			height: 100%;
        }
    }

    &__note {
		color: #cdcdcd;
		margin: 13px 0px 0px 0px;
		font-size: 15px;
		text-align: justify;
    }
}
