html {
    display: flex;
}

body, .container-wrapp {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
    }
}

.wrapper {
    flex: 1;
}

header, .section--header,
footer, .section--footer {
    flex-shrink: 0;
    width: 100%;
}

main {
    flex-grow: 1;
}