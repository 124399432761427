.partner {
    display: flex;
    align-items: center;
	margin-bottom: 15px;

    &__img {
		flex: 0 0 105px;
        max-width: 105px;
    }

    &__description {
        color: #cdcdcd;
        font-size: 11px;
        font-family: Tahoma, Verdana, Arial, sans-serif;
        font-weight: normal;
		line-height: (12/11);
		margin-left: 5px;
    }
}
