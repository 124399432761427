.slide-popular {
    padding: 0 7px;

    &__link {
		color: #cdcdcd;
        font-family: Arial, sans-serif;
        font-size: 18px;
        font-weight: normal;
		line-height: 1;

        img {
            width: 100%;
            border: 3px solid #d9b579;
            box-shadow: 0 0 4px 1px #111;
        }
    }

    &__heading {
		margin-top: 16px;
		margin-bottom: 12px;
    }

    &__properties {
		line-height: 1.2em;
		color: #cdcdcd;
    }

    &__criterion {
        span {
			color: #EFB75C
        }
    }
}
