.service {
	display: flex;
	margin-bottom: 20px;

	&__heading {
		margin-bottom: 7px;
		line-height: 1;
	}

	&__description {
		font-size: 15px;
		text-align: justify;
	}

	&__more {
		a {
			font-size: 15px;
			color: #EFB75C;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}