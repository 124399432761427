/* ==========================================================================
   #SECTION
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

.section {
    @include clearfix; // padding: 0 10px;
    font-family: $font-family-copy;
    width: 100%;
    margin: 0 auto;
}

.section--header {
    margin-bottom: 15px;
}

.section--footer {
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 100px;
}

.section--categories {
    margin-bottom: 15px;
}
